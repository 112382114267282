var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card-code',{attrs:{"title":"Form Kejadian Penting"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Perihal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perihal","label-for":"Perihal"}},[_c('b-form-input',{attrs:{"id":"Perihal","placeholder":"Input Perihal"},model:{value:(_vm.Perihal),callback:function ($$v) {_vm.Perihal=$$v},expression:"Perihal"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kategori","label-for":"Kategori"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","placeholder":"Pilih Kategori","options":_vm.optionsKategori},model:{value:(_vm.kategori),callback:function ($$v) {_vm.kategori=$$v},expression:"kategori"}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tanggal Mulai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tanggal Mulai","label-for":"Tanggal Mulai"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"date-datepicker","locale":"id"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tanggal Selesai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tanggal Selesai","label-for":"Tanggal Selesai"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"end-datepicker","locale":"id"},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File Surat","label-for":"FileSurat"}},[_c('b-form-file',{ref:"file",attrs:{"id":"FileSurat","type":"file","placeholder":"Input File Surat","drop-placeholder":"Drop file here..."},on:{"change":_vm.fileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag"}},[_c('b-form-tags',{attrs:{"input-id":"tags-basic","placeholder":"Tambah Tag"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Deskripsi")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"3"},model:{value:(_vm.deskripsi),callback:function ($$v) {_vm.deskripsi=$$v},expression:"deskripsi"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Catatan")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"3"},model:{value:(_vm.catatan),callback:function ($$v) {_vm.catatan=$$v},expression:"catatan"}})],1)],1),_c('b-button',{staticClass:"bg-gradient-primary ",attrs:{"variant":"outline-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Simpan")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }