<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Form Kejadian Penting">
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Input Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Kategori"
            label-for="Kategori"
          >
            <v-select
              v-model="kategori"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih Kategori"
              :options="optionsKategori"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Mulai"
            rules="required"
          >
            <b-form-group
              label="Tanggal Mulai"
              label-for="Tanggal Mulai"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="date"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Selesai"
            rules="required"
          >
            <b-form-group
              label="Tanggal Selesai"
              label-for="Tanggal Selesai"
            >
              <b-form-datepicker
                id="end-datepicker"
                v-model="date_end"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="File Surat"
              label-for="FileSurat"
            >
              <!-- Styled -->
              <b-form-file
                id="FileSurat"
                ref="file"
                v-model="file"
                type="file"
                placeholder="Input File Surat"
                drop-placeholder="Drop file here..."
                @change="fileChange"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tag"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <label for="textarea-default">Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="deskripsi"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>

        <b-col md="6">
          <label for="textarea-default">Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="catatan"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary "
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
  },
  data() {
    return {
      NoSurat: '',
      Perihal: '',
      file: [],
      tags: [],
      date: null,
      date_end: null,
      kategori: [],
      selected: [],
      deskripsi: '',
      catatan: '',
      options: [{ item: '', name: '' }],
      optionsJenis: [
        { value: 1, text: 'Transaksi' },
        { value: 2, text: 'Non Transaksi' },
      ],
      optionsKategori: [
        { value: 1, text: 'Produk,' },
        { value: 2, text: 'Layanan' },
        { value: 3, text: 'Proses Produksi,' },
        { value: 4, text: 'Pengiriman,' },
        { value: 5, text: 'Paska Pengiriman,' },
        { value: 6, text: 'Harga' },
      ],
    }
  },
  mounted() {
    this.getRole()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Event',
        },
      })
      this.file = data.data
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addRequest()
        }
      })
    },

    async addRequest() {
      await axios
        .post('siap/event/create', {
          cat: this.kategori.text,
          title: this.Perihal,
          from_date: dayjs(this.date).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          to_date: dayjs(this.date_end).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          desc: this.deskripsi,
          note: this.catatan,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // window.location.href = `detail-disposisi/${response.data.data.id}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.errors.map(e => e.message),
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getRole() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_all: 'true',
        },
      })
      this.options = data.map(e => ({
        value: e.role.id,
        text: e.role.name,
        userID: e.user.id,
        receiverType: 'Responder',
      }))
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
</style>
